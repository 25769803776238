import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Loading from "../components/Loading";
import API_BASE_URL from './const.js';
import MultiStockAnalysis from './MultiStockAnalysis';
import './StockRecommendationChart.css';
import StockSignalsDashboard from './StockSignalsDashboard';
import StockTradeLogs from "./StockTradeLogs";

const StockRecommendationChart = () => {
    const [stockName, setStockName] = useState('NVDA');
    const [buyDate, setBuyDate] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [activeTab, setActiveTab] = useState('chart');

    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory(); // Initialize useHistory

    // 添加美国股市假期列表
    const US_HOLIDAYS = [
        // 2024年假期
        '2024-01-01', // 元旦
        '2024-01-15', // 马丁路德金纪念日
        '2024-02-19', // 总统日
        '2024-03-29', // 耶稣受难日
        '2024-05-27', // 阵亡将士纪念日
        '2024-06-19', // 六月节
        '2024-07-04', // 独立日
        '2024-09-02', // 劳动节
        '2024-11-28', // 感恩节
        '2024-12-25', // 圣诞节

        // 2025年假期
        '2025-01-01', // 元旦
        '2025-01-20', // 马丁路德金纪念日
        '2025-02-17', // 总统日
        '2025-04-18', // 耶稣受难日
        '2025-05-26', // 阵亡将士纪念日
        '2025-06-19', // 六月节
        '2025-07-04', // 独立日
        '2025-09-01', // 劳动节
        '2025-11-27', // 感恩节
        '2025-12-25', // 圣诞节

        // 2026年假期
        '2026-01-01', // 元旦
        '2026-01-19', // 马丁路德金纪念日
        '2026-02-16', // 总统日
        '2026-04-03', // 耶稣受难日
        '2026-05-25', // 阵亡将士纪念日
        '2026-06-19', // 六月节
        '2026-07-03', // 独立日（7月4日是周六）
        '2026-09-07', // 劳动节
        '2026-11-26', // 感恩节
        '2026-12-25'  // 圣诞节
    ];

    // 判断是否为交易日
    const isBusinessDay = (date) => {
        const d = new Date(date);
        const dayOfWeek = d.getDay();
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        const dateString = date.split('T')[0];
        const isHoliday = US_HOLIDAYS.includes(dateString);
        
        return !isWeekend && !isHoliday;
    };

    // 添加获取最近交易日的函数
    const getLatestBusinessDay = (date) => {
        let currentDate = new Date(date);
        
        // 最多往前查找10天，避免无限循环
        for (let i = 0; i < 10; i++) {
            if (isBusinessDay(currentDate.toISOString().split('T')[0])) {
                return currentDate.toISOString().split('T')[0];
            }
            currentDate.setDate(currentDate.getDate() - 1);
        }
        return date; // 如果没找到，返回原始日期
    };

    useEffect(() => {
        const today = new Date();
        const latestBusinessDay = getLatestBusinessDay(today);
        setBuyDate(latestBusinessDay);
    }, []);

    // 修改日期验证函数
    const isDateValid = (date) => {
        const selectedDate = new Date(date);
        const minDate = new Date('2020-01-01');
        const today = new Date();
        
        if (!isBusinessDay(date)) {
            setError('Please select a valid trading day (not weekend or holiday).');
            return false;
        } else if (selectedDate < minDate) {
            setError('Date must be after 2020-01-01.');
            return false;
        } else if (selectedDate > today) {
            setError('Date cannot be in the future.');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const isValidStock = async (stock) => {
        const url = `${API_BASE_URL}/api/validate_stock?stock_name=${stock}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.valid;
        } catch (error) {
            console.error('Error validating stock symbol:', error);
            return false;
        }
    };

    const handleAnalyze = async () => {
        if (!buyDate) {
            setError('Please select a buy date.');
            return;
        }

        if (!isDateValid(buyDate)) {
            return;
        }

        const isValid = await isValidStock(stockName);
        if (!isValid) {
            setError('Invalid stock symbol. Please enter a valid stock symbol, e.g., NVDA for Nvidia, AAPL for Apple Inc.');
            return;
        }

        setLoading(true);
        setError('');

        const token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                `${API_BASE_URL}/api/get_stock_analysis?stock_name=${stockName}&buy_date=${buyDate}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail);
            }
            const apiData = await response.json();
            console.log('API Response:', apiData);
            const formattedData = apiData.data.map(item => ({
                ...item,
                close: Number(item.close.toFixed(2)),
                profit: item.profit !== null ? Number(item.profit.toFixed(2)) : null
            }));
            setData(formattedData);
            
            // Match the exact property names from the API response
            setAnalysisResult({
                Stock_Date: apiData.Stock_Date,
                Signal: apiData.Signal,
                BuyScore: apiData.BuyScore,
                Analysis: apiData.Analysis
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getRecommendationText = (buyOrSell) => {
        if (buyOrSell === 1) return 'Buy';
        if (buyOrSell === 0) return 'Sell';
        if (buyOrSell === -1) return 'Neutral';
        return 'Unknown';
    };

    const getRecommendationColor = (buyOrSell) => {
        if (buyOrSell === 1) return 'green';
        if (buyOrSell === 0) return 'red';
        if (buyOrSell === -1) return 'grey';
        return 'grey';
    };

    const getYDomain = (data) => {
        if (data.length === 0) return [0, 'auto'];
        const prices = data.map(item => item.close);
        const min = Math.min(...prices);
        const max = Math.max(...prices);
        return [min * 0.95, max * 1.05]; // Adjust the range slightly to add padding
    };

    return (
        <div className="stock-chart">
            <div className="tab-container">
                <button
                  className={`tab-button ${activeTab === 'chart' ? 'active' : ''}`}
                  onClick={() => setActiveTab('chart')}
                >
                    Stock Analysis
                </button>
                <button
                  className={`tab-button ${activeTab === 'multi' ? 'active' : ''}`}
                  onClick={() => setActiveTab('multi')}
                >
                    Multi Stock Analysis
                </button>
                <button
                  className={`tab-button ${activeTab === 'dashboard' ? 'active' : ''}`}
                  onClick={() => setActiveTab('dashboard')}
                >
                    Last Month Stock Signals
                </button>
                <button
                  className={`tab-button ${activeTab === 'trade-logs' ? 'active' : ''}`}
                  onClick={() => setActiveTab('trade-logs')}
                >
                    AI Trade Logs
                </button>
            </div>

            {activeTab === 'chart' && (
              <div className="chart-tab">
                  <div className="input-container">
                      <input
                        type="text"
                        value={stockName}
                        onChange={(e) => setStockName(e.target.value)}
                            placeholder="Stock symbol (e.g., NVDA)"
                            className="input"
                        />
                        <input
                            type="date"
                            value={buyDate}
                            onChange={(e) => setBuyDate(e.target.value)}
                            className="input"
                            min="2020-01-01"
                            max={new Date().toISOString().split('T')[0]}
                            onKeyDown={(e) => e.preventDefault()} // 防止手动输入
                        />
                        <button onClick={handleAnalyze} className="button" disabled={loading}>
                            {loading ? 'Analyzing...' : 'Analyze Stock'}
                        </button>
                    </div>
                    {error && <div className="error">{error}</div>}
                    {loading && <div className="loading">Loading, it usually takes around 15 seconds</div>}
                    {analysisResult && (
                        <div className="analysis-result" style={{ backgroundColor: getRecommendationColor(analysisResult.Signal) }}>
                            <h3>Analysis Result</h3>
                            <p>Stock Date: {analysisResult.Stock_Date}</p>
                            <p>Recommendation: <b>{getRecommendationText(analysisResult.Signal)}</b></p>
                            <p>Buy Score: {analysisResult.BuyScore}</p>
                            <p>Analysis:</p>
                            <p className="analysis-text">{analysisResult.Analysis}</p> {/* Display analysis with line breaks */}
                        </div>
                    )}

                    {data.length > 0 && (
                        <div className="chart-container">
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={data} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        tick={{ fontSize: 10 }}
                                        interval={'preserveStartEnd'}
                                        tickFormatter={(value) => value}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        tick={{ fontSize: 10 }}
                                        tickFormatter={(value) => `$${value.toFixed(2)}`}
                                        domain={getYDomain(data)}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        tick={{ fontSize: 10 }}
                                        tickFormatter={(value) => `${value}%`}
                                    />
                                    <Tooltip />
                                    <Legend wrapperStyle={{ fontSize: 10, paddingTop: 10 }} />
                                    <Line type="monotone" dataKey="close" stroke="#8884d8" yAxisId="left" name="Stock Price" dot={false} />
                                    <Line type="monotone" dataKey="profit" stroke="#82ca9d" yAxisId="right" name="Profit (%)" dot={false} />
                                    <ReferenceLine
                                        x={buyDate}
                                        stroke="green"
                                        label={{ value: "Buy Date", fontSize: 10, position: 'top' }}
                                        yAxisId="left" 
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                    {/* <button onClick={() => history.push('/about')} className="button">
                        Know More About This Signal
                    </button> */}
                    <div>
                        Check the signal within the last hour before market close to predict the stock's trend for the next two weeks; if it says buy or sell, there's an 85% probability the price will move as indicated.
                    </div>
                    <button
                        onClick={() => history.push('/about')}
                        className="button"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#555',
                            border: 'none',
                            padding: '5px',
                            fontSize: '14px',
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}
                    >
                        Learn More About This Signal
                    </button>
                </div>
            )}

            {activeTab === 'dashboard' && (
                <div className="dashboard-tab">
                    <StockSignalsDashboard />
                </div>
            )}

            {activeTab === 'multi' && (
                <div className="dashboard-tab">
                    <MultiStockAnalysis />
                </div>
            )}

            {activeTab === 'trade-logs' && (
              <div className="dashboard-tab">
                  <StockTradeLogs />
              </div>
            )}
        </div>
    );
};

export default withAuthenticationRequired(StockRecommendationChart, {
    onRedirecting: () => <Loading />,
});
